export const appCdnURL = "https://cdn.ticketme.biz/static/shop/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=2.0"},{"hid":"title","property":"title","content":"TicketMe"},{"hid":"og:title","property":"og:title","content":"TicketMe"},{"hid":"description","name":"description","content":"Покупайте билеты на мероприятия в Абхазии онлайн – TicketMe"},{"hid":"og:description","property":"og:description","content":"Покупайте билеты на мероприятия в Абхазии онлайн – TicketMe"},{"hid":"keywords","property":"keywords","content":"Абхазия, Сухум, Гагра, Гудаута, Ткуарчал, Гал, Очамчыра, Гулрыпш, купить билеты, тикет ми, тикетми, тикетмибиз, расписание, афиша, билет"},{"hid":"url","name":"url","content":"https://ticketme.biz"},{"hid":"og:url","property":"og:url","content":"https://ticketme.biz"},{"hid":"image","name":"image","content":"/ticketme.png"},{"name":"og:image","property":"og:image","content":"/ticketme.png"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"TicketMe"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"